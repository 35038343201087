import React from 'react'

const withContainer = Component => props => {
  const options = [
    { label: '15 min', value: 15 * 60 },
    { label: '30 min', value: 30 * 60 },
    { label: '45 min', value: 45 * 60 },
    { label: '1h', value: 60 * 60 },
    { label: '1h30', value: 90 * 60 },
    { label: '2h', value: 120 * 60 },
    { label: '2h30n', value: 150 * 60 },
    { label: '3h', value: 180 * 60 },
    { label: '4h', value: 240 * 60 },
  ]
  const mergedProps = {
    options
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
