import React from 'react'
import { I18n } from '@front/volcanion'

import { FormDialog, DialogContent } from '@front/squirtle'

import PasswordChangeForm from '../../Popup/PasswordChange'
import GsmChangeForm from '../../Popup/GsmChange'

const Dialogs = ({ submitGsmChangePopup, submitPasswordChangePopup, ...props }) => <>
  <FormDialog
    name={'password_change_form'}
    formProps={{
      onSubmit: submitPasswordChangePopup,
      includeReadOnly: true
    }}
    submitLabel={I18n.t('action.modify')}
  >
    <DialogContent>
      <PasswordChangeForm />
    </DialogContent>
  </FormDialog>
  <FormDialog
    name={'gsm_change_form'}
    formProps={{ onSubmit: submitGsmChangePopup }}
    submitLabel={I18n.t('action.verify')}
  >
    <DialogContent>
      <GsmChangeForm />
    </DialogContent>
  </FormDialog>
</>

export default React.memo(Dialogs)
