class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter({ archive, ...values }, extra, meta, state) {
      return {
        ...values,
        or: [{ favorite: 1, type: null }, { type: ['HOME', 'WORK'] }]
      }
    }
  }
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
        log_query: true
      })
    }
  }

}

export default Callbacks
