import React from 'react'
import { I18n } from '@front/volcanion'

import { TextField, Icon } from '@front/squirtle'
import { InputAdornment } from '@mui/material'

const Temperature = props =>
  <TextField
    label={I18n.t('temperature.celcius')}
    name={'temperature'}
    type={'number'}
    InputProps={{
      sx: { maxWidth: 70 },
      inputProps: { style: { textAlign: 'right' } },
      endAdornment: <InputAdornment>
        <Icon icon={'thermostat_auto'} />
      </InputAdornment>
    }}
  />

export default React.memo(Temperature)
