import React from 'react'
import { I18n } from '@front/volcanion'

import { TextField, Icon } from '@front/squirtle'
import { InputAdornment } from '@mui/material'

const Comment = ({ driver_comment, ...props }) =>
  <TextField
    name='driver_comment'
    label={I18n.t('comment.length.default', { count: (driver_comment || '').length })}
    maxLength={250}
    placeholder={I18n.t('preference.comment_placeholder')}
    multiline
    rowsw={2}
    InputProps={{
      inputProps: {
        style: {
          fontSize: 14
        }
      },
      startAdornment: <InputAdornment sx={{ p: 1 }}>
        <Icon icon={'speaker_notes'} />
      </InputAdornment>
    }}
  />

export default React.memo(Comment)
