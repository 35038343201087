import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [preferred_language] = hooks.useFormState('preferred_language')
  const MUSIC_LIST = hooks.useRelayConfig('MUSIC_LIST')

  const options = _.compact(_.map(MUSIC_LIST, music => ({
    label: music[preferred_language] || music['fr'],
    value: music[preferred_language] || music['fr']
  })))

  const mergedProps = {
    options
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
