import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Collapse, Grid, Typography } from '@mui/material'
import { TextField, MenuSelectorField } from '@front/squirtle'
import { Groups } from '@mui/icons-material'

const SearchForm = ({ requestSubmit, order_status, options, tab, ...props }) => <>
  <Collapse in timeout={1000}>
    <Box sx={{ alignItems: 'center', bgcolor: 'extraWhite', p: 3, boxShadow: 6 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 28 }}>
            <Groups sx={{ color: 'primary.main', fontSize: 'inherit' }} />
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontSize: 21, color: 'primary.main', letterSpacing: 1.5 }}> {I18n.t('companyuser.own')}: </Typography>
            </Box>
          </Box>

        </Grid>
        <Grid item xs>
          <TextField name={'contact.last_name'} label={I18n.t('name.label', { count: 1 })} />
        </Grid>
        <Grid item xs>
          <TextField name={'contact.first_name'} label={I18n.t('first_name.label', { count: 1 })} />
        </Grid>
        <Grid item xs>
          <TextField name={'contact.email'} label={I18n.t('email.label', { count: 1 })} />
        </Grid>
        <Grid item xs>
          <TextField name={'services.contract.client_id'} label={I18n.t('companycontract.label', { count: 1 })} />
        </Grid>
        <Grid item xs>
          <TextField name={'services.client_id'} label={I18n.t('companyservice.label', { count: 1 })} />
        </Grid>
        <Grid item xs>
          <MenuSelectorField
            name={'statusName'}
            label={I18n.t('status.label', { count: 1 })}
            options={[
              { label: I18n.t('status.active'), value: 'active' },
              { label: I18n.t('status.inactive'), value: 'inactive' }
            ]}
            noneLabel={I18n.t('all')}
          />
        </Grid>
      </Grid>
    </Box>
  </Collapse >
</>



export default React.memo(SearchForm)
