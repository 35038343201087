import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [driver_comment] = hooks.useFieldValues(['driver_comment'])

  const mergedProps = {
    driver_comment
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
