import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const [user_id] = hooks.useActiveUser({ mutator: user => user?.user_id })
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const formProps = {
    name: 'user_preference',
    model_name: 'user',
    record_id: user_id,
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationHandler(), []),
    initialState: {},
    populate: ['customerinfo', 'preference', 'info'],
    includeReadOnly: true,
    submitChangesOnly: false,
    onModify: updateUser
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps} >
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
