import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ gsm, ...props }) => {
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const { openNotification } = hooks.useNotification()
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const resendCode = useCallback(async () => {
    try {
      await updateUser({ updates: { auth: { gsm } } })
    } catch (err) {
      openNotification(I18n.t('gsm.code.resend_success'), { variant: 'success' })
      console.log(err)
    }
  }, [updateUser, openNotification])

  const afterCodeChange = useCallback(code => {
    !!code && code.length === 4 && requestSubmit()
  }, [requestSubmit])

  const mergedProps = {
    gsm,
    resendCode,
    afterCodeChange
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer