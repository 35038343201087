import React, { useState } from 'react'
import { I18n } from '@front/volcanion'

import { ExpandPanelComponent, ExpandPanelTitle, ExpandPanelContent } from '@front/squirtle'
import { Grid, Box, Typography } from '@mui/material'

import List from './List'

const Title = props =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, borderColor: 'secondary.main', mt: 1, display: 'flex' }}>
      <Typography sx={{ fontSize: '1.3vw', color: 'secondary.main', p: 2 }}>
        {I18n.t('user.subscribers')}
      </Typography>
    </Box>
  </Grid>

const Subscribe = ({ hasSubscribers, ...props }) => {
  const [open, toggleOpen] = useState(false)
  return (
    <>
      <Box sx={{ display: !!hasSubscribers ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: '90%', boxShadow: 6, borderRadius: 1, pb: 2 }}>
          <ExpandPanelComponent
            open={open}
            toggleOpen={toggleOpen}
            accordionSummaryProps={{ sx: { padding: 0 } }}
            sx={{ backgroundColor: 'extraWhite', pr: 3 }}
          >
            <ExpandPanelTitle>
              <Title />
            </ExpandPanelTitle>
            <ExpandPanelContent>
              <List {...props} />
            </ExpandPanelContent>
          </ExpandPanelComponent>
        </Box>
      </Box>
    </>
  )
}
export default React.memo(Subscribe)
