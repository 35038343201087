import React from 'react'
import { I18n } from '@front/volcanion'

import { MenuSelectorField } from '@front/squirtle'

const OpenDoor = ({ options, ...props }) =>
  <MenuSelectorField
    name={'open_door'}
    options={options}
    allowNone={false}
    label={I18n.t('preference.open_door')}
    getOptionProps={option => ({ fontSize: 14 })}
  />


export default React.memo(OpenDoor)
