
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(forced_filter) {
    return function formToFilter(values, extra, meta, state) {
      const { statusName, ...rest } = values

      return _.merge(
        {},
        rest,
        { status: _.has(values, 'statusName') ? [{ name: statusName }] : [{ name: 'active' }, { name: 'inactive' }] },
        forced_filter
      )
    }
  }
}

export default Callbacks
