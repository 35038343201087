import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, removeAddress, openAddressChangePopup, ...props }) => {
  const { address_id, type, favorite } = row

  const onDelete = useCallback(() => {
    removeAddress(_.merge({}, { filter: { address_id } }, {
      updates: !!type ? { type: null, favorite: 0 } : { favorite: !favorite }
    }))
  }, [address_id, type, favorite, removeAddress])

  const onModify = useCallback(e => openAddressChangePopup(row), [row, openAddressChangePopup])

  const mergedProps = {
    row,
    onDelete,
    onModify
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
