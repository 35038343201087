import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'


const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [inPanel] = hooks.useGlobalState('inPanel')

  const { DISPLAY_PREF } = hooks.useRelayFeatures(['DISPLAY_PREF'])

  const mergedProps = {
    DISPLAY_PREF,
    inPanel
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
