import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const [user] = hooks.useActiveUser()
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [companyuserservices] = hooks.useModelSearch('companyuserservices', 'get', {
    initial_filter: {
      companyuser: { status: [{ name: 'active' }] },
      companyservice: { status: [{ name: 'active' }] }
    }
  })
  const user_id = user?.user_id
  const mergedProps = {
    hasSubscribers: !_.isEmpty(companyuserservices),
    user_id,
    requestSubmit
  }

  return <Loader isLoading={!user}>
    <Component {...mergedProps} {...props} />
  </Loader>

}

export default withContainer