import React, { useCallback } from 'react'

import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [password_new] = hooks.useFieldValues(['password_new'])
  const [password_confirm] = hooks.useFieldValues(['password_confirm'])
  const mergedProps = {
    password_new,
    password_confirm
  }

  const formValidation = useCallback(() => {
    if (!password_confirm || !password_new) return
    else if (password_confirm !== password_new)
      return [{ field: 'password_confirm', message: I18n.t('password.change.error.match') }]
  }, [password_new, password_confirm])

  hooks.useFormValidation(formValidation)

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer