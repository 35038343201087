import { hooks } from '@front/volcanion'

const useApplication = (display_index = 0, options = {}) => {
  const [applications] = hooks.useModelSearch('application', 'get', { initial_filter: {} })
  return _.find(applications, ['display_index', display_index])
}

export {
  useApplication
}
