import React from 'react'
import { I18n } from '@front/volcanion'

import { SingleOptionSelectorField, Icon } from '@front/squirtle'

const AirConditioning = ({ air_conditioning }) =>
  <SingleOptionSelectorField
    label={I18n.t('preference.air_conditioning')}
    labelPlacement={'end'}
    optionLabel={<Icon icon={'ac_unit'} sx={{ color: !!air_conditioning ? 'primary.main' : 'gray' }} />}
    getOptionLabelProps={option => ({ labelPlacement: 'end' })}
    formGroupProps={{ sx: { pl: 2, mt: 2 } }}
    name={'air_conditioning'}
    valueType='switch'
    size={'small'}
  />

export default React.memo(AirConditioning)
