import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Divider, Grid, Typography } from '@mui/material'

import { TextField } from '@front/squirtle'

import PasswordComplexity from '@onix/elements/complexity/password'

const PasswordChangeForm = ({ password_new, ...props }) => <>
  <Grid container justifyContent='center' rowSpacing={2}>
    <Grid item xs={12}>
      <Box sx={{ borderLeft: 4, borderColor: 'primary.main', mt: 1, }}>
        <Typography sx={{ fontSize: 'h5.fontSize', color: 'primary.main', p: 2, fontWeight: 300 }}>
          {I18n.t('password.change.label')}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={8}>
      <TextField
        label={I18n.t('password.label.old')}
        name='password_old'
        type='password'
        required
      />
    </Grid>
    <Grid item xs={8}>
      <TextField
        label={I18n.t('password.label.new')}
        name='password_new'
        type='password'
        required
      />
    </Grid>
    <Grid item xs={12} hidden={!password_new}>
      <Divider />
      <Box sx={{ p: 1 }}>
        <PasswordComplexity value={password_new} fontSize={{ titleSize: 15, textSize: 13 }} iconSize={18} disableTitle={false} />
      </Box>
      <Divider />
    </Grid>
    <Grid item xs={8}>
      <TextField
        label={I18n.t('confirmation')}
        name='password_confirm'
        type='password'
        required
      />
    </Grid>
  </Grid>
</>


export default React.memo(PasswordChangeForm)
