import React, { useCallback } from 'react'

import { ModelForm } from '@front/volcanion'
import { hooks } from '@front/volcanion'
import { useApplication as useMainApplication } from '@onix/hooks/application'


import Callbacks from './callbacks'

const withForm = Component => props => {
  const { companyuser_id } = hooks.useParams()
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const default_application = _.get(useMainApplication(), 'application_id')

  const requestSubmitCompanyservice = hooks.useGlobalFormFunction('companyuserservices_list', 'requestSubmit')

  const formProps = {
    record_id: companyuser_id,
    name: `companyuser_form`,
    model_name: 'companyuser',
    populate: ['user.auth', 'user.info', 'user.customerinfo', 'contact'],
    initialState: { isReadOnly: !!companyuser_id },
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(selectedCompany, default_application), [selectedCompany, default_application]),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(requestSubmitCompanyservice), [requestSubmitCompanyservice]),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
