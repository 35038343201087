import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [isReadOnly, setReadOnly] = hooks.useFormState('isReadOnly')

  const onEdit = useCallback(e => {
    setReadOnly(false)
    e.stopPropagation()
  }, [setReadOnly])

  const features = hooks.useRelayFeatures([
    'DISPLAY_PREF_WAITING_DURATION',
    'DISPLAY_PREF_PREFERRED_MUSIC',
    'DISPLAY_PREF_AIR_CONDITIONING',
    'DISPLAY_PREF_TEMPERATURE',
    'DISPLAY_PREF_OPEN_DOOR',
    'DISPLAY_PREF_OPEN_WINDOW',
    'DISPLAY_PREF_ARMREST',
    'DISPLAY_PREF_COMMENT_TO_DRIVER'
  ])

  const mergedProps = {
    requestSubmit,
    isReadOnly,
    onEdit,
    ...features
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer