class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
      }
    }
  }
  static formToFilterHandler(user_id) {
    return function formToFilter(values, extra, meta, state) {
      return {
        companyuser: {
          status: [{ name: 'active' }],
          user: user_id
        },
        companyservice: { status: [{ name: 'active' }] },
        ...values
      }
    }
  }
}

export default Callbacks
