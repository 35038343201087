import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const isReadOnly = hooks.useFormState('isReadOnly')

  const mergedProps = {
    isReadOnly
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
