import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { Icon, TableCell, Typography } from '@mui/material'

import GeoUtils from '@front/volcanion/utils/geo'

const Row = ({ row, isSelected, ...props }) => {
  const address_id = _.get(row, 'address_id')
  const type = _.get(row, 'type')

  const formatted_address = useMemo(() => GeoUtils.format(row), [address_id])

  return <>
    <TableCell size='small' padding='none' >
      <Icon
        sx={{ fontSize: '2vw' }}
        color={'primary'}
        fontSize={'inherit'}
        baseClassName='material-icons'
        className={type || 'star_border'}
      >
        {type || 'star_border'}
      </Icon>
    </TableCell>
    <TableCell size='small' >
      <Typography align='left' color='primary' fontWeight={600} fontSize={'1.1vw'} > {I18n.t(`address.${type || 'favorite'}`)} </Typography>
      <Typography align='left' fontSize={'0.8vw'} color='text.label'> {formatted_address} </Typography>
    </TableCell>
  </>
}

export default React.memo(Row)
