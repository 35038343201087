import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Button, Typography, Icon } from '@mui/material'

const ButtonGlobalAction = ({ icon = 'add', onClick, ...props }) => <>
  <Button
    color='primary'
    fullWidth
    sx={{ textTransform: 'none' }}
    variant='outlined'
    onClick={onClick}
    size={'small'}
    {...props}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1 }}>
      <Icon sx={{ fontSize: '2vw' }} baseClassName='material-icons'> {icon} </Icon>
      <Typography sx={{ fontSize: '0.9vw', mt: '1px', fontWeight: 600, ml: 2 }}>  {I18n.t('address.add.label', { count: 1 })} </Typography>
    </Box>
  </Button>
</>


export default React.memo(ButtonGlobalAction)
