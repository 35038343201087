import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Box, Typography, Collapse } from '@mui/material'

import { AddressAutocompleteField, MenuSelectorField, MapFactory } from '@front/squirtle'

const AddressPopup = ({ type = 'one', address_coordinates, onAddressResolve }) => <>
  <Grid container justifyContent='center' alignItems='flex-end' rowSpacing={2} spacing={2}>
    <Grid item xs={12}>
      <Box sx={{ borderLeft: 4, borderColor: 'primary.main', mt: 1, }}>
        <Typography sx={{ fontSize: 'h5.fontSize', color: 'primary.main', p: 2, fontWeight: 300 }}>
          {I18n.t(`address.add.label.${type}`)}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={11}>
      <Collapse in>
        <MapFactory.MapContainer
          mapStyle={{ height: 300 }}
          mapId='select_address_type'
        >
          <MapFactory.SuperLayer fitBounds>
            <MapFactory.Marker
              position={address_coordinates}
              styles={{ color: 'green', icon: 'room' }}
            />
          </MapFactory.SuperLayer >
        </MapFactory.MapContainer>
      </Collapse>
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        name={'type'}
        options={[
          { value: 'home', iconName: 'home', iconSize: 26 },
          { value: 'work', iconName: 'work', iconSize: 26 },
          { value: 'favorite', iconName: 'star_border', iconSize: 26 }
        ]}
        required
        getOptionIcon={option => _.get(option, 'iconName')}
        getOptionIconProps={option => ({ size: option?.iconSize, icon: option?.iconName })}
      />
    </Grid>
    <Grid item xs={10}>
      <AddressAutocompleteField
        name={'address'}
        label={I18n.t('address.label', { count: 1 })}
        noOptionsText={I18n.t('no_result')}
        onAddressResolve={onAddressResolve}
        resolveAddress
        initialConfig={{
          includePOI: false,
          includeFavorite: false,
          includeRecent: false
        }}
      />
    </Grid>
  </Grid>
</>


export default React.memo(AddressPopup)
