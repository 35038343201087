import React from 'react'

import { Box } from '@mui/material'

import { DeleteRowAction } from '@front/squirtle/components/FormTable/Actions/'
import TableAction from '@front/squirtle/components/Table/Action'

const ButtonComponent = ({ onDelete, onModify }) =>
  <Box sx={{ display: 'flex' }}>
    <TableAction onClick={onModify} label={null} icon='edit' iconProps={{ sx: { fontSize: '1.5vw', color: 'primary.main' } }} />
    <DeleteRowAction onClick={onDelete} label={null} disableConfirm iconProps={{ sx: { fontSize: '2vw', color: 'primary.main' } }} />
  </Box>

export default React.memo(ButtonComponent)
