import React from 'react'
import { I18n } from '@front/volcanion/'

import { Box, Grid, Typography } from '@mui/material'

import { TextField, MenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography sx={{ color: 'text.label', fontSize: 15, fontWeight: 600 }}>{I18n.t('information.personal')} </Typography>
  </Grid>

const Row1 = ({ isManagingRecord, normalizePhone }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField
        name={'contact.gsm'}
        label={I18n.t('gsm.label', { count: 1 })}
        normalize={normalizePhone}
        debounceTime={250}
        required
        disabled={!!isManagingRecord}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.first_name'}
        label={I18n.t('first_name.label', { count: 1 })}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.last_name'}
        label={I18n.t('name.label', { count: 1 })}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.email'}
        label={I18n.t('email.label', { count: 1 })}
        required
      />
    </Grid>
  </Grid>

const Row2 = ({ color, status, isReadOnly }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={3}>
      <MenuSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={_.compact([
          { label: <Box sx={{ color: 'green', fontWeight: 500 }}> {I18n.t('status.active')}  </Box>, value: 'active' },
          { label: <Box sx={{ color: 'orange', fontWeight: 500 }}> {I18n.t('status.inactive')}  </Box>, value: 'suspended' },
          { label: <Box sx={{ color: 'red', fontWeight: 500 }}> {I18n.t('status.deleted')}  </Box>, value: 'deleted' },
        ])}
        valueDecoratorProps={{
          sx: {
            WebkitTextFillColor: color,
            fontWeight: 600
          }
        }}
        selectProps={!!isReadOnly ? { IconComponent: null } : {}}
        allowNone={false}
      />
    </Grid>
    <Grid item xs />
  </Grid>

const PrimarySection = props => <>
  <Box sx={{ p: 2, border: 1, borderColor: 'text.label', borderRadius: 1 }}>
    <Grid container rowSpacing={2}>
      <Title />
      <Row1 {...props} />
      <Row2 {...props} />
    </Grid>
  </Box>
</>

export default React.memo(PrimarySection)
