import React from 'react'
import { I18n } from '@front/volcanion'

import { MenuSelectorField } from '@front/squirtle'
import { Grid, } from '@mui/material'

const WaitingDuration = ({ options }) =>
  <Grid item xs={12}>
    <MenuSelectorField
      name={'waiting_duration'}
      options={options}
      noneLabel={I18n.t('unlimited')}
      label={I18n.t('preference.waiting_duration')}
      getOptionProps={option => ({ color: 'primary', fontSize: 14 })}
      getOptionIconProps={option => ({ icon: 'hourglass_empty', fontSize: 'small' })}
    />
  </Grid>


export default React.memo(WaitingDuration)
