import React from 'react'
import { I18n, hooks } from '@front/volcanion'

import { Box, Icon, TableCell, Typography } from '@mui/material'

const Row = ({ row }) => {
  const name = _.get(row, 'companyuser.company.name')
  const contract_name = _.get(row, 'companyservice.contract.name')
  const service_name = _.get(row, 'companyservice.name')
  const service_id = _.get(row, 'companyservice.companyservice_id')

  const [{
    payment_types,
  }] = hooks.useAuthorizedPaymentTypes({ service: service_id })

  return <>
    <TableCell size='small'>
      <Box display='flex' alignItems='center'>
        <Box sx={{ marginRight: 5 }}>
          <Icon
            sx={{ fontSize: '2vw' }}
            color={'primary'}
            fontSize={'inherit'}
            baseClassName='material-icons'
            className={'contact_page'}
          >
            {'contact_page'}
          </Icon>
        </Box>
        <Box>
          <Typography color='primary' sx={{ fontSize: '1vw', fontWeight: 600 }}> {I18n.t('account.label.alter', { name })} </Typography>
          <Typography sx={{ fontSize: '1vw', color: 'text.label' }}> {contract_name} - {service_name}</Typography>
          <Box>
            <Typography sx={{ fontSize: '0.8vw', color: 'text.label' }}> {I18n.t('payment.authorized')} </Typography>
            <Typography sx={{ fontSize: '0.8vw', color: 'text.label', fontWeight: 600, ml: 1 }}> {(_.join(_.compact(_.map(payment_types, 'name_translated')), ', '))} </Typography>
          </Box>
        </Box>
      </Box>
    </TableCell>
  </>
}

export default React.memo(Row)
