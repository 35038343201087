import React, { useState } from 'react'
import { I18n } from '@front/volcanion'

import { ExpandPanelComponent, ExpandPanelTitle, ExpandPanelContent } from '@front/squirtle'
import { Grid, Divider, Button, Typography, Box } from '@mui/material'
import { AirConditioning, ArmRest, Comment, OpenDoor, OpenWindow, PreferredMusic, Temperature, WaitingDuration } from './Section/'

const Title = props =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, borderColor: 'secondary.main', display: 'flex' }}>
      <Typography sx={{ fontSize: '1.3vw', color: 'secondary.main', p: 2 }}>
        {'Mes préférences de voyage'}
      </Typography>
    </Box>
  </Grid>

const Preference = ({
  DISPLAY_PREF_WAITING_DURATION,
  DISPLAY_PREF_PREFERRED_MUSIC,
  DISPLAY_PREF_OPEN_DOOR,
  DISPLAY_PREF_AIR_CONDITIONING,
  DISPLAY_PREF_TEMPERATURE,
  DISPLAY_PREF_OPEN_WINDOW,
  DISPLAY_PREF_ARMREST,
  DISPLAY_PREF_COMMENT_TO_DRIVER,
  requestSubmit,
  isReadOnly,
  onEdit
}) => {

  const [open, toggleOpen] = useState(false)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: '90%', boxShadow: 6, borderRadius: 1, pb: 2 }}>
        <ExpandPanelComponent
          open={open}
          toggleOpen={toggleOpen}
          accordionSummaryProps={{ sx: { padding: 0 } }}
          sx={{ backgroundColor: 'extraWhite', pr: 3 }}
        >
          <ExpandPanelTitle>
            <Title />
          </ExpandPanelTitle>
          <ExpandPanelContent>
            <Grid container spacing={2} rowSpacing={1.5} justifyContent='flex-end'>
              <Grid item xs={12} hidden={!DISPLAY_PREF_WAITING_DURATION}><WaitingDuration /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_WAITING_DURATION}><Divider /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_PREFERRED_MUSIC}><PreferredMusic /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_ARMREST}><Divider /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_ARMREST}><ArmRest /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_OPEN_DOOR}><Divider /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_OPEN_DOOR}><OpenDoor /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_OPEN_WINDOW}><Divider /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_OPEN_WINDOW}><OpenWindow /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_TEMPERATURE || !DISPLAY_PREF_AIR_CONDITIONING}><Divider /></Grid>
              <Grid item container xs={12}>
                <Grid item xs={12} md={6} hidden={!DISPLAY_PREF_TEMPERATURE}><Temperature /></Grid>
                <Grid item xs={12} md={6} hidden={!DISPLAY_PREF_AIR_CONDITIONING}><AirConditioning /></Grid>
              </Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_COMMENT_TO_DRIVER}><Divider /></Grid>
              <Grid item xs={12} hidden={!DISPLAY_PREF_COMMENT_TO_DRIVER}><Comment /></Grid>
              <Grid item xs={6}>
                <Box display='flex' justifyContent='flex-end'>
                  {!!isReadOnly && <Button onClick={onEdit}>{I18n.t('action.modify')}</Button>}
                  {!isReadOnly && <Button onClick={requestSubmit}>{I18n.t('action.confirm')}</Button>}
                </Box>
              </Grid>
            </Grid >
          </ExpandPanelContent>
        </ExpandPanelComponent>
      </Box>
    </Box >
  )
}

export default React.memo(Preference)
