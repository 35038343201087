import React from 'react'

import { Box, Stack } from '@mui/material'

import UserInfoSection from './Section/UserInfo'
import SubscribeSection from './Section/Subscribe'
import AddressSection from './Section/Address'
import PreferenceSection from './Section/Preference'

const UserFrame = ({ DISPLAY_PREF }) =>
  <Box sx={{ pb: 3 }}>
    <Stack spacing={2}>
      <UserInfoSection />
      <SubscribeSection />
      {!!DISPLAY_PREF && <PreferenceSection />}
      <AddressSection />
    </Stack>
  </Box>

export default React.memo(UserFrame)
