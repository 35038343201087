import { I18n } from '@front/volcanion'

class Callbacks {
  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        preferred_language: record?.info?.preferred_language || 'fr'
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        waiting_duration: record?.preference?.waiting_duration,
        preferred_music: record?.preference?.preferred_music,
        open_door: record?.preference?.open_door,
        air_conditioning: record?.preference?.air_conditioning,
        temperature: record?.preference?.temperature,
        open_window: record?.preference?.open_window,
        armrest: record?.preference?.armrest,
        driver_comment: record?.customerinfo?.comment_to_driver
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        customerinfo: {
          comment_to_driver: values?.driver_comment
        },
        preference: {
          waiting_duration: values?.waiting_duration,
          preferred_music: values?.preferred_music,
          open_door: values?.open_door,
          air_conditioning: values?.air_conditioning,
          temperature: values?.temperature,
          open_window: values?.open_window,
          armrest: values?.armrest
        }
      }
    }
  }

  static getSuccessNotificationHandler() {
    return function getSuccessNotification(err, values, extra, meta, state) {
      return [I18n.t('preference.success'), { variant: 'success' }]
    }
  }


}

export default Callbacks
