import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [open_window] = hooks.useFieldValues(['open_window'])

  const mergedProps = {
    open_window
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer