import React from 'react'

import { Collapse } from '@mui/material'
import { TextField } from '@front/squirtle'

const SearchForm = props => <>
  <Collapse in={false} timeout={1000}>
    <TextField name='test' />
  </Collapse >
</>


export default React.memo(SearchForm)
