import { I18n } from '@front/volcanion/'

import UserUtils from '@front/volcanion/utils/user'
import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        contact: {
          gsm: _.get(record, 'contact.gsm'),
          email: _.get(record, 'contact.email'),
          last_name: _.get(record, 'contact.last_name'),
          first_name: _.get(record, 'contact.first_name'),
        },
        status: FormatUtils.getStatus(_.pick(record, ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])),
        suspendedSince: _.get(record, 'suspendedSince'),
        suspendedUntil: _.get(record, 'suspendedUntil'),
        administration_level: _.get(record, 'administration_level'),
        booking_level: _.get(record, 'booking_level'),
        self_booking: _.get(record, 'self_booking'),
        guest_booking: _.get(record, 'guest_booking')
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        status: 'active'
      }
    }
  }

  static formToRecordHandler(company, application) {
    return function formToRecord(values, extra, meta, state) {
      return _.merge(
        !state?.user_id ? {
          user: {
            auth: {
              gsm: _.get(values, 'contact.gsm'),
              application
            },
            customerinfo: {}
          }
        } : {},
        {
          company,
          contact: {
            gsm: _.get(values, 'contact.gsm'),
            email: _.get(values, 'contact.email'),
            last_name: _.get(values, 'contact.last_name'),
            first_name: _.get(values, 'contact.first_name')
          },
          suspendedSince: _.get(values, 'suspendedSince'),
          suspendedUntil: _.get(values, 'suspendedUntil'),
          administration_level: _.get(values, 'administration_level'),
          booking_level: _.get(values, 'booking_level'),
          self_booking: _.get(values, 'self_booking'),
          guest_booking: _.get(values, 'guest_booking'),
          status: FormatUtils.parseStatus(_.get(values, 'status')),
        })
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        client_id: _.get(record, 'user.customerinfo.client_number'),
        name: UserUtils.getFormatedName(record?.contact),
        user_id: _.get(record, 'user.user_id'),
        company: _.get(record, 'company')
      }
    }
  }

  static onSubmitSuccessHandler(requestSubmitCompanyservice) {
    return async function onSubmitSuccess(result, values, extra, meta, state) {
      requestSubmitCompanyservice({ companyuser: result?.companyuser_id, omit_success_notification: true })
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta) {
      if (_.includes(['RECORD_EXISTS'], err.getCode())) {
        return [I18n.t('companyuser.record_exists'), { variant: 'error' }]
      }
    }
  }
}

export default Callbacks
