import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Box, Grid, IconButton, Button } from '@mui/material'

import { TextPhoneInputField, TextField } from '@front/squirtle'

import { SaveRounded, EditRounded, Close } from '@mui/icons-material/'

import Dialogs from './dialogs'

const RowButton = ({ isReadOnly, onChangeReadOnly, onRequestSubmit, ...props }) =>
  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

    <Box sx={{ display: !isReadOnly ? 'flex' : 'none' }}>
      <IconButton onClick={onRequestSubmit} sx={{ color: 'green' }}>
        <SaveRounded />
      </IconButton>
    </Box>
    <Box sx={{ display: !isReadOnly ? 'flex' : 'none' }}>
      <IconButton onClick={onChangeReadOnly} sx={{ color: 'redDark' }}>
        <Close />
      </IconButton>
    </Box>

    <Box sx={{ display: !!isReadOnly ? 'flex' : 'none' }}>
      <IconButton onClick={onChangeReadOnly} sx={{ color: 'primary.main' }}>
        <EditRounded />
      </IconButton>
    </Box>
  </Box >

const Row2 = props =>
  <Grid item xs={12}>
    <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <Box>
        <RowButton {...props} />
      </Box>
      <TextField
        name='info.last_name'
        InputProps={{ inputProps: { style: { marginLeft: '6px', fontSize: '1.6vw', fontWeight: 500, textAlign: 'start' } } }}
      />
      <TextField
        name='info.first_name'
        InputProps={{
          inputProps: { style: { fontSize: '1.6vw', fontWeight: 500, textAlign: !!props?.isReadOnly ? 'end' : 'start' } }
        }}
      />
    </Box>
  </Grid>


const Row3 = ({ countryLang, onCountryLangChange, SUPPORTED_LANGUAGES, ...props }) =>
  <Grid container item xs={11} justifyContent='center' alignItems='center' spacing={3}>
    <Grid item xs={12} md={6}>
      <TextPhoneInputField
        label={I18n.t('gsm.label.short')}
        name='auth.gsm'
        defaultCountry={'fr'}
        variant='outlined'
        InputLabelProps={{
          style: {
            fontWeight: 200
          }
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        label={'Email'}
        name='auth.email'
        variant='outlined'
        InputLabelProps={{
          style: {
            fontWeight: 200
          }
        }}
      />
    </Grid>
  </Grid>

const Row4 = ({ openPasswordChangePopup }) =>
  <Grid item xs={12} sm={6}>
    <Button onClick={openPasswordChangePopup} sx={{ textTransform: 'none' }}>
      <Typography textAlign='center' sx={{ textDecoration: 'underline' }} fontSize='1.0vw'>{I18n.t('password.change.click')}</Typography>
    </Button>
  </Grid>

const UserInfoSection = ({ submitGsmChangePopup, submitPasswordChangePopup, openPasswordChangePopup, ...props }) => <>
  <Dialogs submitGsmChangePopup={submitGsmChangePopup} submitPasswordChangePopup={submitPasswordChangePopup} />
  <Grid container justifyContent='center' rowSpacing={2} sx={{ pt: 2 }}>
    <Row2  {...props} />
    <Row3 {...props} />
    <Row4 openPasswordChangePopup={openPasswordChangePopup} />
  </Grid>
</>


export default React.memo(UserInfoSection)
