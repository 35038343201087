import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion/'

import { TableCell } from '@mui/material'
import { ModelMenuSelectorField } from '@front/squirtle'

const defaultTableCellProps = {
  align: 'center',
  size: 'small'
}

const Row = ({ row, isSelected, company, ...props }) => {
  const [selectedCompany] = hooks.useGlobalState('selectedCompany')
  const initialRoleFilter = hooks.useCompanyFilter(selectedCompany, { model_name: 'companycontract' })

  const [, setSectionValues] = hooks.useSection(hooks.useSectionPath())
  const onContractChange = useCallback(() => {
    setSectionValues([{ field: 'service', value: null }])
  }, [setSectionValues])

  return (
    <>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'contract'}
          model_name='companycontract'
          labelKeys={['name']}
          searchMode='search'
          allowNone={false}
          disabled
          selectProps={{ IconComponent: null }}
        />
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'contract'}
          model_name='companycontract'
          labelKeys={['client_id']}
          searchMode='search'
          allowNone={false}
          config={{
            forced_filter: _.merge({}, initialRoleFilter, { active: 1 }),
            watchers: [initialRoleFilter]
          }}
          loadOnFocus
          afterChange={onContractChange}
        />
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'service'}
          model_name='companyservice'
          labelKeys={['name']}
          searchMode='search'
          allowNone={false}
          disabled
          selectProps={{ IconComponent: null }}
          loadOnFocus
        />
      </TableCell>
      <TableCell {...defaultTableCellProps}>
        <ModelMenuSelectorField
          name={'service'}
          model_name='companyservice'
          labelKeys={['client_id']}
          searchMode='search'
          allowNone={false}
          config={{
            forced_filter: { contract: row?.contract, active: 1 },
            validate_filter: useCallback(() => !!row?.contract, [row?.contract]),
            watchers: [row?.contract]
          }}
          loadOnFocus
          disabled={!row?.contract}
        />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
