import React from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [client_id] = hooks.useFormState('client_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [name] = hooks.useFormState('name')

  const mergedProps = {
    isManagingRecord,
    name,
    client_id
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
