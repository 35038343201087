import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const [dialogs, { openDialog }] = hooks.useDialogs()
  const { openNotification } = hooks.useNotification()
  const [removeAddress] = hooks.useModelFunction('address', 'modify')
  const [toggleFavorite] = hooks.useModelFunction('address', 'toggleFavorite')
  const [changeType] = hooks.useModelFunction('address', 'changeType')
  const submitAddressFormPopup = useCallback(PopupCallbacks.submitAddressFormPopupHandler(toggleFavorite, changeType, openNotification, removeAddress), [toggleFavorite, changeType, openNotification, removeAddress])
  const openAddressChangePopup = useCallback(PopupCallbacks.openAddressChangePopupHandler(openDialog), [openDialog])

  const mergedProps = {
    removeAddress,
    submitAddressFormPopup,
    openAddressChangePopup,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer