import React from 'react'
import { I18n } from '@front/volcanion'

import { SingleOptionSelectorField } from '@front/squirtle'
import { Typography, Box } from '@mui/material'

const ArmRest = ({ armrest }) =>
  <>
    <Typography sx={{ fontSize: 12, color: 'text.label' }}>
      {I18n.t('armrest.label', { count: -1 })} :
    </Typography>
    <Box display={'flex'} alignItems={'flex-end'} >
      <Box sx={{ mb: '6px' }}>
        <Typography color={!armrest ? 'primary.main' : 'text.label'} sx={{ fontSize: 14 }}>
          {I18n.t('placement.down.label', { count: -1 })}
        </Typography>
      </Box>
      <SingleOptionSelectorField
        name={'armrest'}
        valueType='switch'
        optionLabel={I18n.t('placement.up.label', { count: -1 })}
        disableTypography
        getOptionLabelProps={() => ({
          disableTypography: false,
          componentsProps: {
            typography: {
              fontSize: 14,
              color: !!armrest ? 'primary.main' : 'text.label'
            },
          },
          labelPlacement: 'end'
        })}
        formGroupProps={{ sx: { pt: 0, pl: 2 } }}
      />
    </Box>
  </>


export default React.memo(ArmRest)