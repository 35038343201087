import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [armrest] = hooks.useFieldValues(['armrest'])

  const mergedProps = {
    armrest
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer