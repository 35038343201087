import React, { useCallback } from 'react'

import { Grid } from '@mui/material'
import { TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Callbacks from './callbacks'

import Row from './Row'
import Form from './Form'

const SubscribeSection = ({ user_id, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(user_id), [user_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])

  return <Grid item xs={11}>
    <SearchFrame
      model_name={'companyuserservices'}
      populate={['companyuser.company', 'companyservice.contract']}
      allowCreate={false}
    >
      <SearchForm
        submitOnMount
        disableButton
        filterToForm={filterToForm}
        formToFilter={formToFilter}
        filterToState={filterToState}
      >
        <FormContent><Form /></FormContent>
      </SearchForm>
      <SearchTable
        tableProps={{
          sx: { border: 1, boderColor: 'primary.main' },
          enablePagination: false,
          onRowClick: null
        }}
      >
        <TableRow><Row /></TableRow>
      </SearchTable>
    </SearchFrame >
  </Grid>
}

export default React.memo(SubscribeSection)
