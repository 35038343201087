import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'

import PopupCallbacks from './popupCallbacks'

const withContainer = Component => props => {
  const [, signin] = hooks.useAuthFunction('signin')
  const setPasswordFormValues = hooks.useGlobalFormChange('password_change_form_dialog_form')
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const { openNotification } = hooks.useNotification()
  const [, changePassword] = hooks.useAuthFunction('changePassword')
  const [meta] = hooks.useFormMeta()
  const [gsm] = hooks.useFieldValues(['auth.gsm', 'auth.email'])
  const [, { openDialog, closeDialog }] = hooks.useDialogs()
  const requestSubmit = hooks.useFormFunction('requestSubmit')
  const [isReadOnly, setReadOnly] = hooks.useFormState('isReadOnly')
  const isGsmDirty = !!_.get(_.find(meta, ['field', 'auth.gsm']), 'dirty')

  const onChangeReadOnly = useCallback(() => setReadOnly(!isReadOnly), [setReadOnly, isReadOnly])
  const submitGsmChangePopup = useCallback(PopupCallbacks.submitGsmChangePopupHandler(updateUser, openNotification, onChangeReadOnly, gsm, signin), [updateUser, openNotification, onChangeReadOnly, gsm, signin])
  const openGsmChangePopup = useCallback(PopupCallbacks.openGsmChangePopupHandler(openDialog, closeDialog, updateUser, gsm), [openDialog, closeDialog, updateUser, gsm])
  const submitPasswordChangePopup = useCallback(PopupCallbacks.submitPasswordChangePopupHandler(openNotification, changePassword, setPasswordFormValues), [openNotification, changePassword, setPasswordFormValues])
  const openPasswordChangePopup = useCallback(PopupCallbacks.openPasswordChangePopupHandler(openDialog), [openDialog])
  const onRequestSubmit = useCallback(() => {
    if (!!isGsmDirty)
      openGsmChangePopup(gsm)
    else
      requestSubmit()

  }, [isGsmDirty, openGsmChangePopup, gsm, requestSubmit])

  const mergedProps = {
    onChangeReadOnly,
    onRequestSubmit,
    isReadOnly,
    submitGsmChangePopup,
    openGsmChangePopup,
    openPasswordChangePopup,
    submitPasswordChangePopup
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer