import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import FormatUtils from '@front/squirtle/utils/format'

const withContainer = Component => props => {
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [status] = hooks.useFieldValues(['status'])
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')

  const normalizePhone = useCallback(Callbacks.normalizePhoneHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL])
  const color = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    normalizePhone,
    isManagingRecord,
    isReadOnly,
    status,
    color
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
