import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell, Typography, Box } from '@mui/material'

const defaultTableCellProps = {
  align: 'center',
  size: 'small',
  sx: { border: 0 }
}

const TextHeader = ({ label, Icon, IconProps = {}, ...props }) =>
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    {!!Icon && <Box sx={{ color: 'text.label', mr: 1, ...IconProps }}> <Icon color='inherit' /></Box>}
    <Typography sx={{ color: 'text.label', textAlign: 'center', fontSize: 15, fontWeight: 600 }}>
      {label}
    </Typography>
  </Box>

const Header = props => <>
  <TableCell {...defaultTableCellProps}>
    <TextHeader label={I18n.t('name.label', { count: 1 })} />
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <TextHeader label={I18n.t('first_name.label', { count: 1 })} />
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <TextHeader label={I18n.t('email.label', { count: 1 })} />
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <TextHeader label={I18n.t('update.last')} />
  </TableCell>
  <TableCell {...defaultTableCellProps}>
    <TextHeader label={I18n.t('status.label', { count: 1 })} />
  </TableCell>
</>

export default React.memo(Header)
