import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ form_name = 'user_default_form', user_id }) => {
  const [, updateUser] = hooks.useAuthFunction('updateUser')
  const formProps = {
    name: form_name,
    model_name: 'user',
    record_id: user_id,
    populate: ['auth', 'info', 'customerinfo'],
    initialState: {
      isReadOnly: true
    },
    redirectOnGenerate: false,
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    getSuccessNotification: useCallback(Callbacks.getSuccessNotificationdHandler(), []),
    getFailedNotification: useCallback(Callbacks.getFailedNotificationHandler(), []),
    onModify: updateUser
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
