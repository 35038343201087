import React, { useCallback, useEffect } from 'react'

import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'
import GeoUtils from "@front/volcanion/utils/geo"

const withContainer = Component => ({ record_id, ...props }) => {
  const setFormValues = hooks.useFormChange()

  const [record, { isLoading }] = hooks.useModel('address', [record_id], { single: true })
  const [type, address_details] = hooks.useFieldValues(['type', 'address_details'])

  const address_coordinates = address_details?.coordinates

  useEffect(() => {
    if (!record_id || !!isLoading) return
    setFormValues([{ field: 'address', value: GeoUtils.getFormattedObject(record) }, { field: 'type', value: _.get(record, 'type') || 'favorite' }])
  }, [isLoading])

  const onAddressResolve = useCallback(Callbacks.handleOnAddressResolve(setFormValues), [setFormValues])

  const mergedProps = {
    type,
    address_coordinates,
    onAddressResolve
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
