import moment from 'moment'

import { I18n } from '@front/volcanion'


import UserUtils from '@front/volcanion/utils/user'
import FormatUtils from '@front/squirtle/utils/format'
import GeoUtils from "@front/volcanion/utils/geo"

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        info: {
          first_name: _.get(record, 'info.first_name'),
          last_name: _.get(record, 'info.last_name')
        },
        auth: {
          gsm: _.get(record, 'auth.gsm'),
          email: _.get(record, 'auth.email')
        }
      }
    }
  }
  static recordToStateHandler() {
    return function recordToState(record) {
      return {

      }
    }
  }
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
      })
    }
  }
  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        ...values
      }
    }
  }
  static onSubmitSuccessHandler(openResumeOrderPopup) {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err)
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification() {
      return [I18n.t('notification.user.failed'), { variant: 'error' }]
    }
  }
  static getSuccessNotificationdHandler() {
    return function getSuccessNotificationd() {
      return [I18n.t('notification.user.success'), { variant: 'success' }]
    }
  }
}

export default Callbacks
