import React from 'react'

const withContainer = Component => props => {

  const mergedProps = {
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
