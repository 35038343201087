import React from 'react'
import { I18n } from '@front/volcanion'

const withContainer = Component => props => {
  const options = [
    { label: I18n.t('ever'), value: 'always' },
    { label: I18n.t('preference.open_door_depends'), value: 'depends' },
    { label: I18n.t('never'), value: 'never' }
  ]
  const mergedProps = {
    options
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer