import React from 'react'
import { I18n } from '@front/volcanion'

import { SingleOptionSelectorField } from '@front/squirtle'

const OpenWindow = props =>
  <SingleOptionSelectorField
    label={`${I18n.t('preference.open_window')}`}
    name={'open_window'}
    valueType='switch'
  />


export default React.memo(OpenWindow)
