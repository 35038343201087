import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [air_conditioning] = hooks.useFieldValues(['air_conditioning'])

  const mergedProps = {
    air_conditioning
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
