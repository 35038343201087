import React, { useCallback } from 'react'

import { TableRow, TableRowActions, SearchFrame, SearchForm, SearchTable, SearchCreateButton, FormContent } from '@front/squirtle'

import { Grid } from '@mui/material'

import Callbacks from './callbacks'

import ButtonRow from './ButtonRow'
import Row from './Row'
import Form from './Form'
import Button from './Button'

const ServiceSection = ({ openAddressChangePopup, removeAddress }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  const filterToState = useCallback(Callbacks.filterToStateHandler(), [])
  const formToOptions = useCallback(Callbacks.formToOptionsHandler(), [])

  return (
    <Grid item xs={11}>
      <SearchFrame
        model_name={'address'}
        createPlacement={'bottom'}
      >
        <SearchForm
          submitOnMount
          disableButton
          getButtonStyleProps={() => ({ justifyContent: 'flex-start' })}
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          filterToState={filterToState}
          formToOptions={formToOptions}
        >
          <FormContent><Form /></FormContent>
        </SearchForm>
        <SearchTable
          watch
          formProps={{
            initialState: {
              isReadOnly: false
            }
          }}
          tableProps={{
            enableRowActionsHeader: false,
            enableToolbar: false,
            enablePagination: false,
            enableRowActions: true,
            enableRowSelection: false,
            enableGlobalActions: true,
            enableGlobalActionsPlacement: 'bottom',
            disableCancelRowLabel: true,
            disableSaveRowLabel: true,
            sx: { border: 1, boderColor: 'primary.main' },
            enablePagination: false,
            onRowClick: openAddressChangePopup
          }}
        >
          <TableRow><Row /></TableRow>
          <TableRowActions> <ButtonRow openAddressChangePopup={openAddressChangePopup} removeAddress={removeAddress} /></TableRowActions>
        </SearchTable>
        <SearchCreateButton>
          <Button onClick={() => openAddressChangePopup()} />
        </SearchCreateButton>
      </SearchFrame >
    </Grid >
  )
}

export default React.memo(ServiceSection)
