import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'
import { TableCell, Typography } from '@mui/material'

import FormatUtils from '@front/squirtle/utils/format'

const defaultTableCellProps = {
  align: 'center',
  size: 'small'
}

const defaultTypographyProps = {
  sx: {
    fontSize: 15,
    textAlign: 'center',
    color: 'text.label'
  }
}

const Row = ({ row, isSelected, ...props }) => {

  const status = useMemo(() => FormatUtils.getStatus(row, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']), [row?.auth])
  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])
  return <>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {row?.contact?.last_name ?? '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {row?.contact?.first_name ?? '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {row?.contact?.email ?? '-'}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography {...defaultTypographyProps}> {FormatUtils.formatDateBackToFront(_.get(row, 'contact.updatedAt'), 'DD/MM/YYYY HH:mm')}</Typography>
    </TableCell>
    <TableCell {...defaultTableCellProps}>
      <Typography color={statusColor} fontWeight={'bold'}>{I18n.t(`status.${status}`)}</Typography>
    </TableCell>
  </>
}

export default React.memo(Row)
