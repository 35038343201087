import { I18n } from '@front/volcanion'

class PopupCallbacks {
  static submitGsmChangePopupHandler(updateUser, openNotification, onChangeReadOnly, gsm, signin) {
    return async function submitGsmChangePopup(values, b, c, d) {
      const { code } = values
      try {
        await updateUser({ updates: { auth: { gsm, code }, code } })
        onChangeReadOnly()
        openNotification(I18n.t('gsm.code.success'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('gsm.code.incorrect'), { variant: 'error' })
        throw err
      }
    }
  }
  static openGsmChangePopupHandler(openDialog, closeDialog, updateUser) {
    return function openGsmChangePopup(gsm) {
      updateUser({ updates: { auth: { gsm } } })
      openDialog('gsm_change_form', { gsm, closeDialog: () => closeDialog('gsm_change_form') })
      return {}
    }
  }
  static submitPasswordChangePopupHandler(openNotification, changePassword, setPasswordFormValues) {
    return async function submitPasswordChangePopup(values) {
      const { password_old, password_new } = values
      try {
        await changePassword({ new_password: password_new, old_password: password_old })
        openNotification(I18n.t('password.change.success'), { variant: 'success' })
      } catch (err) {
        setPasswordFormValues([
          { field: 'password_old', meta: { error: true, field_error: I18n.t('password.change.error.incorrect') } }
        ])
        openNotification(I18n.t('password.change.error.incorrect'), { variant: 'error' })
        throw err
      }
      return
    }
  }
  static openPasswordChangePopupHandler(openDialog) {
    return function openPasswordChangePopup() {
      openDialog('password_change_form', {})
      return {}
    }
  }
}

export default PopupCallbacks
