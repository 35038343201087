import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Box, Link, Typography } from '@mui/material'

import { PhoneVerificationField } from '@front/squirtle'

const Title = ({ gsm, ...props }) =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, borderColor: 'primary.main', mt: 1 }}>
      <Typography sx={{ fontSize: 18, color: 'primary.main', fontWeight: 300, ml: 1 }}>
        {I18n.t('popup.gsm.title')} <b> {gsm}</b>
      </Typography>
    </Box>
  </Grid>

const Code = ({ afterCodeChange, ...props }) =>
  <Grid item xs={6}>
    <PhoneVerificationField name='code' autoFocus required afterChange={afterCodeChange} color={'primary'} />
  </Grid>

const Links = ({ resendCode, closeDialog, ...props }) =>
  <Grid container item xs={12} >
    <Grid item xs={12}>
      <Typography sx={{ textAlign: 'center', color: 'text.label' }}>
        {I18n.t('popup.gsm.not_receive')}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Link href='#' onClick={resendCode}>
        <Typography sx={{ textAlign: 'center' }}>
          {I18n.t('popup.gsm.link.resend.by_gsm')}
        </Typography>
      </Link>
    </Grid>
    <Grid item xs={12}>
      <Link href='#' onClick={closeDialog}>
        <Typography sx={{ textAlign: 'center' }}>
          {I18n.t('popup.gsm.link.change.gsm')}
        </Typography>
      </Link>
    </Grid>
  </Grid>

const PasswordChangeForm = props => <>
  <Grid container justifyContent='center' rowSpacing={5}>
    <Title {...props} />
    <Code {...props} />
    <Links {...props} />
  </Grid>
</>

export default React.memo(PasswordChangeForm)
