import React from 'react'
import { I18n } from '@front/volcanion/'

import { Box, Grid, Typography } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { SingleOptionSelectorField, MenuSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography sx={{ color: 'text.label', fontSize: 15, fontWeight: 600 }}>{I18n.t('rights.label', { count: 2 })} </Typography>
  </Grid>

const Row1 = ({ isReadOnly, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('admin.label', { count: 1 })}
        noneLabel={I18n.t('no')}
        name={'administration_level'}
        options={[
          { label: I18n.t('yes'), value: 'company' }
        ]}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name='booking_level'
        label={I18n.t("companyuser.booking.company")}
        options={[
          { label: I18n.t('company.label', { count: 1 }), value: 'company' },
          { label: I18n.t('companycontract.label', { count: 1 }), value: 'contract' },
          { label: I18n.t('companyservice.label', { count: 1 }), value: 'service' },
        ]}
        noneLabel={I18n.t('none')}
        selectProps={{ IconComponent: !!isReadOnly ? null : ArrowDropDown }}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'self_booking'}
        label={I18n.t('companyuser.booking.self')}
        clearOnEmpty={false}
        formGroupProps={{ sx: { justifyContent: 'flex-start', marginTop: '16px' } }}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'guest_booking'}
        label={I18n.t('companyuser.booking.guest')}
        clearOnEmpty={false}
        formGroupProps={{ sx: { justifyContent: 'flex-start', marginTop: '16px' } }}
      />
    </Grid>
  </Grid>

const RightsSection = props => <>
  <Box sx={{ p: 2, border: 1, borderColor: 'text.label', borderRadius: 1 }}>
    <Grid container rowSpacing={2}>
      <Title />
      <Row1 {...props} />
    </Grid>
  </Box >
</>

export default React.memo(RightsSection)
