import React from 'react'
import { I18n } from '@front/volcanion'

import { MenuSelectorField } from '@front/squirtle'


const PreferredMusic = ({ options, ...props }) =>
  <MenuSelectorField
    name={'preferred_music'}
    options={options}
    noneLabel={I18n.t('none_fem')}
    label={I18n.t('preference.preferred_music')}
    getOptionProps={option => ({ color: 'primary', fontSize: 14 })}
    getOptionIconProps={option => ({ icon: option?.value ? 'music_note' : 'music_off', fontSize: 'small' })}
  />


export default React.memo(PreferredMusic)
