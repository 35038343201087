import { I18n } from '@front/volcanion'

class PopupCallbacks {
  static submitAddressFormPopupHandler(toggleFavorite, changeType, openNotification) {
    return async function submitAddressFormPopup(values, extra, meta, state) {
      const { address_id, currentAddress } = extra
      const { address, type } = values

      try {
        if (type === 'favorite') {
          !!address_id && await toggleFavorite({ address: currentAddress, favorite: false })
          await toggleFavorite({ address, favorite: true })
        }
        else {
          await changeType({ address, type })
        }
        openNotification(I18n.t(`address.add.success.${type}`), { variant: 'success' })
      } catch (err) {
        openNotification('Une erreur est survenu lors de l\'ajout aux favoris.', { variant: 'error' })
      }
      return
    }
  }
  static openAddressChangePopupHandler(openDialog) {
    return function openAddressChangePopup(record) {
      openDialog('address_form', { record_id: _.get(record, 'address_id'), record }, { address_id: _.get(record, 'address_id'), currentAddress: record })
      return {}
    }
  }
}

export default PopupCallbacks
