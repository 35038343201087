
import React from 'react'
import { I18n } from '@front/volcanion'

import { Stack } from '@mui/material'

import FormHeader from '@onix/elements/headers/form'
import PrimarySection from './Section/Primary'
import RightsSection from './Section/Rights'
import ServicesSection from './Section/Services'

const User = ({ client_id, name, isManagingRecord }) =>
  <Stack spacing={2} sx={{ boxShadow: 6, borderRadius: 3, p: 2, pt: 0 }}>
    <FormHeader
      icon={'groups'}
      goBackPath={'/admin/users'}
      enableActions
      title={!!isManagingRecord
        ? I18n.t('companyuser.label.dynamic', { client_id, name })
        : I18n.t('companyuser.create', { client_id, name })
      }
    />
    <PrimarySection />
    <RightsSection />
    <ServicesSection />
  </Stack>

export default React.memo(User)
