import React, { useState } from 'react'
import { I18n } from '@front/volcanion'

import { ExpandPanelComponent, ExpandPanelTitle, ExpandPanelContent } from '@front/squirtle'
import { Grid, Box, Typography } from '@mui/material'

import { DialogContent, FormDialog } from '@front/squirtle'

import List from './List'

import AddressForm from '../../Popup/Address'

const Title = props =>
  <Grid item xs={12}>
    <Box sx={{ borderLeft: 4, borderColor: 'secondary.main', display: 'flex' }}>
      <Typography sx={{ fontSize: '1.3vw', color: 'secondary.main', p: 2 }}>
        {I18n.t('address.label.alter')}
      </Typography>
    </Box>
  </Grid>

const Address = ({ submitAddressFormPopup, ...props }) => {
  const [open, toggleOpen] = useState(false)
  return (<>
    <FormDialog
      name={'address_form'}
      formProps={{ onSubmit: submitAddressFormPopup, disableParentListener: true }}
    >
      <DialogContent>
        <AddressForm />
      </DialogContent>
    </FormDialog>

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: '90%', boxShadow: 6, borderRadius: 1, pb: 2 }}>
        <ExpandPanelComponent
          open={open}
          toggleOpen={toggleOpen}
          accordionSummaryProps={{ sx: { padding: 0 } }}
          sx={{ backgroundColor: 'extraWhite', pr: 3 }}
        >
          <ExpandPanelTitle>
            <Title />
          </ExpandPanelTitle>
          <ExpandPanelContent>
            <List {...props} />
          </ExpandPanelContent>
        </ExpandPanelComponent>
      </Box>
    </Box>
  </>
  )
}




export default React.memo(Address)
