import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { IconButton, Box, Grid, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'
import { Navigate } from 'react-router'

const Title = ({ submitSearchTable, isReadOnlyParentForm, isReadOnly, ...props }) =>
  <Grid container item xs={12} spacing={1} alignItems='center'>
    <Grid item xs={11}>
      <Typography sx={{ color: 'text.label', fontSize: 15, fontWeight: 600 }}>{I18n.t('companyservice.associated')} </Typography>
    </Grid>
    <Grid item xs={1} hidden={!isReadOnlyParentForm || !!isReadOnly}>
      <IconButton onClick={submitSearchTable} sx={{ p: 0, color: 'primary.main' }}> <Save /> </IconButton>
    </Grid>
  </Grid >

const ServiceSection = ({ nagivate, record_id, company, ...props }) => {
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const handleRowClick = useCallback((rowId, row) => !!_.get(row, 'service') && window.open(`/admin/service/${_.get(row, 'service')}`), [])

  return (
    <Box sx={{ p: 2, border: 1, borderColor: 'text.label', borderRadius: 1 }}>
      <Grid item container rowSpacing={3}>
        <Title {...props} />
        <Grid item xs={12}>
          <Box>
            <SearchFrame
              model_name={'companyuserservices'}
              populate={['companyuser', 'companyservice.contract']}
              allowCreate={false}
              createPlacement={'bottom'}
            >
              <SearchForm
                filterToForm={filterToForm}
                formToFilter={formToFilter}
                filterToState={filterToState}
                submitOnMount={!!record_id}
                disableButton
              >
                <FormContent><Form /></FormContent>
              </SearchForm>
              <SearchTable
                formProps={{
                  recordToForm,
                  formToRecord,
                  disableParentSubmitListener: true,
                }}
                tableProps={{
                  onRowClick: handleRowClick,
                  enableToolbar: false,
                  enablePagination: false,
                  enableRowActions: true,
                  enableRowSelection: false,
                  enableGlobalActions: true,
                  enableGlobalActionsPlacement: 'bottom',
                  disableCancelRowLabel: true,
                  disableSaveRowLabel: true
                }}
              >
                <TableHeader><Header /></TableHeader>
                <TableRow company={company}><Row /></TableRow>
                <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
                <TableActions><ButtonGlobalAction /></TableActions>
              </SearchTable>
            </SearchFrame >
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
}

export default React.memo(ServiceSection)
